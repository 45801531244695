@font-face {
  font-family: "SFProDisplay";
  src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

body {
  margin: 0;
  font-family: "SFProDisplay", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactTable .rt-resizable-header-content {
    width: 100%;
    height: 100%;
}

.ReactTable .rt-thead .rt-th.-sort-asc {
    box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-asc::before {
    transform: scaleY(-1);
    content: url("public/sort-asc.svg");
    float: left;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
    box-shadow: none !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc::before {
    content: url("public/sort-asc.svg");
    float: left;
}